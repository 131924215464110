// IMPORT

//@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@100;300;400;500;600;700;900&display=swap');

@font-face {
  font-family: 'FoundersGroteskX-Condensed-Semibold';  /* Name of the font family */
  src: url('FoundersGroteskX-Condensed-Semibold.otf') format('opentype');  /* URL to the OTF file */
  font-weight: normal;
  font-style: normal;
}

//================================================
//                     VARIABLES 
//================================================

//General Colors
$primary-color:#070707;
$secondary-color:rgb(228, 228, 228);;
$error-color:#e22929;
$info-color:#e0e242;

$primary-color-body:rgb(228, 228, 228);
$secondary-color-body:#070707;

$botton-hover-color: #ebebeb;

$primary-mobile: white;
$secondary-color-admin: rgb(228, 228, 228);


//Bottom properties
$botton-dark-color:#070707;
$botton-light-color: white;

//admin
$admin-panel-desktop: $primary-color;

//Fonts

//$titlefontSecond: 'Roboto', sans-serif;
//$titlefont: 'Roboto', sans-serif;

$titlefontSecond: 'Helvetica', 'Arial', sans-serif;
$titlefont: 'FoundersGroteskX-Condensed-Semibold', sans-serif;

//Spacing
$base-padding: 1rem;
$base-margin: 0.75rem;

//Borders
$base-border-thickness: 1px;
$base-border-radius: 30px;

//fonts
$small-size: 0.5rem;
$medium-size: 1rem;
$large-size: 2rem;
$x-large-size: 3rem;

$small-weight: 100;
$medium-weight: 300;
$large-weight: 500;
$semibold-weight: 500;


//================================================
//                     SELECTORS 
//================================================

body {
    max-width: 1800px;
    margin: auto;
    background-color: $primary-color-body;
}

h1 {
  font-family: $titlefont;
  font-size: $x-large-size;
  font-weight: $semibold-weight;
  color: $primary-color;
  margin-bottom: $base-margin;
  line-height: 0.75;
}

h2 {
  font-family: $titlefontSecond;
  font-size: 1.5rem;
  font-weight: $large-weight;
  color: $primary-color;
  margin-bottom: $base-margin;
}

h3 {
  font-family: $titlefontSecond;
  font-weight: $medium-weight;
  font-size: $medium-size;
  color: $primary-color;
}

h4 {
  font-family: $titlefontSecond;
  font-weight: $medium-weight;
  font-size: $medium-size;
  color: $primary-color;
}

p, span, td, th, caption {
  font-family: $titlefontSecond;
  font-weight: $medium-weight;
  font-size: $medium-size;
  color: $primary-color;
}

li {
  font-family: $titlefontSecond;
  font-weight: $medium-weight;
  font-size: $medium-size;
  color: $primary-color;
  font-style: italic;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

input, button {
  font-family: $titlefontSecond !important;
  font-weight: $medium-weight;
  font-size: $medium-size;
  color: $primary-color;
  -webkit-appearance: none;  /* Disable default styles in Webkit-based browsers */
  -moz-appearance: none;     /* Disable default styles in Firefox */
  appearance: none; 
}

select, option {
  all: unset;  /* Resets all styles */
  font-family: $titlefontSecond !important;
  font-weight: $medium-weight;
  font-size: $medium-size;
  color: $primary-color;
}

$safe-area-left: env(safe-area-inset-left);
$safe-area-right: env(safe-area-inset-right);
$safe-area-top: env(safe-area-inset-top);
$safe-area-bottom: env(safe-area-inset-bottom);

:root {
  --safe-area-left: 0;
  --safe-area-right: 0;
  --safe-area-top: 0;
  --safe-area-bottom: 0;
}

@supports (bottom: env(safe-area-inset-top)) {
  :root {
    --safe-area-left: env(safe-area-inset-left);
    --safe-area-right: env(safe-area-inset-right);
    --safe-area-top: env(safe-area-inset-top);
    --safe-area-bottom: env(safe-area-inset-bottom);
  }
}

body {
  -webkit-text-size-adjust: none;
}

@-webkit-keyframes move-right {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

//================================================
//                  BTN CLASES 
//================================================

.button-accept {
  
  font-family: $titlefont;
  background-color: $botton-dark-color;
  color: white;
  font-size: $medium-size;
  font-weight: $medium-weight;
  border: $base-border-thickness solid black;
  border-radius: $base-border-radius;
  width: 100%;
  padding: $base-padding;
  margin-top: 20px;

  &:hover {
    background: rgb(49, 49, 49);
  }
}

.button-info {
  font-family: $titlefont;
  background-color: $primary-color-body;
  color: $botton-dark-color;
  font-size: $medium-size;
  font-weight: $medium-weight;
  border: $base-border-thickness solid black;
  border-radius: $base-border-radius;
  width: 100%;
  padding: $base-padding;
  margin-top: 20px;

  &:hover {
    background: $botton-hover-color;
    color: $botton-dark-color;
    //border-color: $botton-light-color;
  }

}

/* Custom input styles to match the button */
.styled-select {
  font-family: $titlefont;
  font-size: $medium-size; /* Adjust as needed */
  background-color: $secondary-color-admin;
  border: 1px solid black; /* Border thickness */
  border-radius: $base-border-radius; /* Border radius */
  width: 300px;
  padding: 10px;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16"><path d="M8.297 11.879l-4.096-4.098a1 1 0 0 1 1.415-1.414L8 10.081l3.384-3.614a1 1 0 0 1 1.415 1.414l-4.096 4.098a1 1 0 0 1-1.415 0z"/></svg>'); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  color: black;
}

//================================================
//               ADD QTY CLASES 
//================================================
//btn
.add-quantity-button {
  background-color: white; /* Button background color */
  //color: #fff; /* Text color */
  border: 1px solid black;
  //border-radius: 50%; /* Makes it round */
  font-size: 1.5rem; /* Adjust the font size as needed */
  width: 30px; /* Set a fixed width */
  height: 30px; /* Set a fixed height */
  cursor: pointer;

  &:hover {
    background-color: #dbdbdb;
  }
}

.add-quantity-button span {
  display: block;
  text-align: center;
  line-height: 30px; /* Centers the plus sign vertically */
  
}

.size-btn-box {
  max-width: 6rem;
  flex: 1;
  margin-top: 1rem
}

.size-btn-box > p {
  text-align: center;
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
}

.btn-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap:5px;

}

.light-size {
  background-color: #0a0a0a;
  color: white;
}


//================================================
//                REDIRECTIONG
//================================================
.redirecting{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  margin: 0rem;

  h2 {
    font-weight: 300;
    font-size: 1rem;
  }
}

//================================================
//                LOG IN 
//================================================ 

//=== Parent
.log-in-container {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */

  h1 {
    font-weight: 300;
  }
  
}

.log-in-info {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px; /* Adjust the width as needed */

  h2 {
    font-size: 50px;
    font-weight: 500;
  }
  
}

.log-in-form {
  //margin-top: 1rem;
  width: 500px; /* Adjust the width as needed */
  //text-align: center;
}

.log-in-btn {
  width: 100%;
}

.title {
  margin: 0;
  text-align: center;
}

.input-log-in-username, .input-log-in-password {
  font-family: $titlefont;
  font-size: $medium-size;
  font-weight: $medium-weight;
  background-color: $primary-color-body;
  border: $base-border-thickness solid black;
  border-radius: $base-border-radius;
  padding: $base-padding;
  width: 466px; //500-34 px 
}

input::placeholder {
  //padding-left: $base-padding;
  font-size: $medium-size;
}

.error {
  
  color: $error-color;
  border: 0;
  //border-color: $error-color;
  //border-style: solid;
  margin: $base-margin;
  //border-width: $base-border-thickness;
  padding: $base-padding $base-padding;
}

.notification {
  color: $info-color;
  border-color: $info-color;
  border-style: solid;
}

//================================================
//                HEADER 
//================================================ 

.header {
  //display: flex;
  display: none;
  justify-content: space-between;
  align-items: center;
  //background-color: #f8f8f8;
  padding: 20px ;
  margin: 1rem 1rem;
  border: 1px solid black;
  //  position: fixed;
  //  left:0;
  // width: 100%;
  //  z-index:10;
  //  box-sizing: border-box;
  //  background-color: white;
}

.logo {
  width: 100px; /* Set the width */
  transform: scale(0.75);
  //height: 200px; /* Set the height */
}

.items {
  display: flex;
  font-family: $titlefont;
  font-size: $medium-size;
  font-weight: $medium-weight;
  align-items: center;
  gap: 20px;
  margin-right: 2rem;
  cursor: pointer;
}

.items p {
  margin-left: 20px;
  text-decoration: none;
  color: #333;
  margin: 0;
  
}

.icon-user-menu-desktop {
  max-width: 25px;
}

.flex-contianer-user-menu {
  display: none;
  position: absolute;
  top: 5%;
  right: 7%;
  background-color: #fff;
  border: 1px solid #292929;
  padding: 10px;
}

.items:hover .flex-contianer-user-menu{
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    margin: 0;
  }
  
}

//================================================
//                FOOTER 
//================================================
.footer-box {
  padding: 180px 2rem;
  // background-color: #0d0e0e;
  background-color: $primary-color;
  h2,h3,p,span {
    color: white;
  }
}

.flex-continer-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top:3px solid white;
  padding: 3rem 0;
  

  p, h3 {
    margin: 0;
  }
}

.footer-contact,  .footer-info{
  display: flex;
  flex-direction: column;
  gap: 10px ;
  align-items: start;
}

.flex-container-copyright {
  padding-top: 180px;

  p{
    font-size: 0.8rem;
    color: gray;
  }
}

.follow-us {
  
  display: flex;
  gap: 10px ;
  align-items: center;
  cursor: pointer;
}

.instagram-icon {
  width: 1.5rem;
}

.logo-footer {
  padding-bottom: 5px;
}

//================================================
//                TERMS
//================================================
.terms-box {
  margin: 0.5rem 2rem;
  margin-bottom: 4rem;
  min-height: 100vh;

  h1 {
    font-size: 4rem; 
    font-weight: 300;
    width: 66%;
    margin: 2rem 0;
  }

  // h3{
  //   font-size: 1.5rem;
  //   font-weight: 300;
  //   margin: 0;
  // }
}

//================================================
//                HOME PAGE PLUS
//================================================ 
//block 1
.dark-header {
  background-color: $primary-color !important;
  
}

.flex-container-home-page-block-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;      /* Horizontally center */
  //background-color: $primary-color;
  //background-color: black;
  //opacity: 0.7;
  
  padding: 20rem 0rem;

  h1,h2,h3,p,span,li {
    color: $primary-color-body;
  }

  h1 {
    margin: 0;
    font-size: 7rem;
    max-width: 100%;
    text-align: center;
    color: white;
  }

  h2 {
    font-size: 2rem;
    font-style: italic;
    font-weight: 300;
    margin: 0;
    //font-size: 2rem;
  }

}

.flex-container-home-page-title {
  display: flex;
  justify-content:center;
  align-items: center;
  max-width: 70%;
  h1 {
    font-size: 13rem;
  }
}

.flex-container-new-collection-items {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  //padding-right: 5rem;
  align-items: center;
  cursor: pointer;
}

.home-page-btn-download {
  background-color: $primary-color-body;
  border: none;
  color: black;
  width: 30%;
}

.home-page-btn-create {
  background-color: $primary-color;
  border: none;
  color: white;
  border: 1px solid white;
  width: 30%;
}

.arrow-exit-white-reverse {
  width: 40px;
  cursor: pointer;
  transform: rotate(180deg);
  color: white;
}

//block 2
//block 2
.flex-container-home-page-block-2 {
  display: flex;
  flex-direction: column;
  padding: 12em 2em 2vh;
  background-color: $primary-color-body;
}

.flex-container-home-page-highlights {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  align-items: start;
  gap: 15px;
  flex: 1;

  h1 {
    font-size: 7rem;
    margin: 0;
    max-width: 50%;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    letter-spacing: 2px;
  }
}

.flex-container-title-detail {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 15px;
  align-items: center;
  min-width: 320px;
  padding-top: 15px;
  padding-right: 2rem;
}

.thin-rectangle {
  width: 7rem; /* Set the width to 3rem */
  height: 2px; /* Set the height to create a thin rectangle */
  background-color: #000; /* Set the background color (you can change this to any color you prefer) */
}

.flex-container-image-description-block {
  display: flex;
  gap: 15px;
  padding: 4rem 0;
  padding-left: 20%;
  flex: 1;
}

.test {
  display: block;
  width: 100%;
}

.flex-container-highlights-description-block {
  display: flex;
  flex-direction: column;
  //gap: 20px;
  padding-left: 20%;
  padding-right: 0%;

  h2 {
    font-weight: 300;
    font-size: 2.5rem;
    margin: 0;
    min-width: 40%;
  }

  p {
    margin:0;
    font-size: 1.2rem;
  }
}

.flex-container-description {
  display: flex;
  flex-direction: column;
  padding-right: 0rem;
  h2 {
    
    font-size: 2rem;
  }
  p {
    margin:0;
    font-size: 1.2rem;
  }
}

.img-home-page {
  max-width: 70%;
}

.flex-container-individual-highlight {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding:1rem 0;
  padding-right: 0rem;
  gap: 10px;

  h2 {
    text-align: left;
    margin: 0;
  }
  p{
    margin: 0;
  }
  
}

.flex-container-individual-highlight-reverse {
  display: flex;
  flex-direction: row;
  align-items: start;
  padding:1rem 0;
  padding-right: 0rem;
  gap: 10px;

  h2 {
    text-align: right;
  }
  
}


//================================================
//                OFFICIAL SUPPLIERS
//================================================
.flex-container-read-article {
  display: flex;
  gap: 15px;
  justify-content: start;
  align-items: center;
  padding-top: 1rem;

  h2{
    margin: 0 !important;
    font-style: italic;
    font-size: 1.2rem !important;
  }

}


//================================================
//                HOME PAGE 
//================================================ 

// .container-home-page-flex-container {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   //height: 70vh;
//   align-items: center;
// }

.box-container-home-page {
  margin: 2rem;
  min-height: 100vh;

}


.grid-container-create-brochure {
  display: grid;
  grid-template-columns: 1fr 1fr;
 
}

.info-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 5rem;
    //font-weight: 400;
    margin: 0;
  }
}

.btn-home-page-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    max-width: 400px;
  }
}


.order-history-details {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 1.5rem !important;
    //margin: 2rem 0rem 1rem 0rem;
    font-weight: 400;
  }
}

.ohd-pt1 {
  max-width: 65%;
}

.btn-create-order-box {
  //max-width: 80%;
}

.create-order-btn {
 margin: 0;
  width: 100%;
  padding: 0.8rem 4rem;
  font-weight: 300;
  background-color: $primary-color;
  color: white;

  &:hover {
    background-color: rgb(37, 37, 37);
    color: white;;
  }
}

.stock-client-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 3rem !important;
    //margin: 2rem 0rem 1rem 0rem;
    font-weight: 300;
  }
}

.stock-explore-btn {
  font-style: italic;
  cursor: pointer;
  text-decoration: underline;
}

.stock-title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 3rem;
  width: 100%;
}

.shop-stock-btn {
  margin: 0;
   width: 100%;
   padding: 0.8rem 4rem;
   font-weight: 300;
   background-color: $primary-color-body;
   color: rgb(0, 0, 0);
 
   &:hover {
     background-color: rgb(223, 223, 223);
     color: rgb(0, 0, 0);;
   }
 }

.vertical-line {
  width: 2px; /* Adjust the width of the line as needed */
      height: 30px; /* Adjust the height of the line as needed */
      background-color: black; /* Set the color of the line */
}

.download-brochure{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  padding: 1rem 2rem;
  margin: 0;
  //border-bottom: 1px solid black;

  h2 {
    font-size: 1.2rem;
    margin: 0;
    cursor: pointer;
  }

  p {
    margin: 0;
    font-style: italic;
    text-align: center;
    text-decoration: underline;
  }
}

.other-work-box {
  padding:1rem 1rem;
  padding-bottom: 3rem;
}

.offer-title {
  font-size: 5rem;
  font-weight: 300;
  margin-top: 3rem;
  width: 50%;
}

.grid-container-other-work {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2rem;
  gap:40px;
 
  
}

.flex-container-work {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Add shadow */
  border-radius: 40px;
  text-align: center;
  max-width: 380px;
  background-color: $primary-color-body;

  p {
    margin: 1rem;
  }

}

.img-work {
  margin-top: 1.5rem;
}

.grid-container-all-season {
  display: flex;
  justify-content: end;
  //grid-template-columns: 1fr 1fr;
  //place-items: center;
  background-color: $primary-color-body;
  margin-top: 3rem;
  padding: 3rem 0;
  

  button {
    width: 400px;
    background-color: #ededed;
    border: 1px solid black;
    color: black;
    margin-left:1rem;

    &:hover {
      background: rgb(231, 230, 230);
    }
  }
}

.flex-container-all-season-info {
  display: flex;
  flex-direction: column;
  //align-items: end;
  margin: 0 ;
  h1,h2,h3,p {
    color:$primary-color
  }
  
  h1 {
    font-size: 5rem;
    margin: 0;
    
  }

  p{
    margin:0;
  }
}

.official-suppliers-title {

    font-size: 5rem;
    margin: 3rem 0;
  
}

.flex-container-official-supplier {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2rem 0rem;
  place-items: center;
  padding-left: 17%;

  button {
    width: 100%;
  }

  h1 {
    margin: 0;
    font-weight: 300;
    
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 300;
    margin: 0;
    margin-top: 1rem;
  }
  h3{
    font-size: 1.5rem;
    font-style: italic;
    margin: 0;
    margin-top: 5px;
  }
  
}

.img-the-open {
  max-width: 12rem;
  margin-bottom: 2rem;
}

.img-the-solheim {
  max-width: 10rem;
  margin-bottom: 2rem;
}

.order-history > p {
  font-size: 1.5rem ;
  
}

.flex-container-client-status {
  display: flex;
  
  justify-content:start;
  gap: 10px;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }

  h3 {
    font-style: italic;
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
  }

  p {
    text-decoration: underline;
    font-style: italic;
    margin: 0;
   //color: #1099c6;
    cursor: pointer;
  }
}

.flex-container-status-items {
  display: flex;
  gap: 5px;
}

.btn-all-season {
  background-color: white !important;
}

//placehokders
$loader-gradient-color: linear-gradient(to right, #d6d6d6, #dedede);
.loading-placeholder-box {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //height: 80px;
}

.loading-placeholder-b1 {
  //height: 100px;
}

.loading-placeholder-title {
  margin-top: 1rem;
  height: 30px;
  width: 100px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-1 {
  margin-top: 1rem;
  height: 20px;
  width: 400px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-2 {
  display: none;
  margin-top: 1rem;
  height: 20px;
  width: 300px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-btn {
  margin-top: 1rem;
  height: 40px;
  width: 250px;
  background: $loader-gradient-color;
  border-radius: 25px;
}

//placeholder admin
.loading-placeholder-box-admin {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //height: 80px;
}

.loading-placeholder-b1-admin {
  //height: 100px;
}

.loading-placeholder-title-admin {
  margin-top: 1rem;
  height: 30px;
  width: 100px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-1-admin {
  margin-top: 1rem;
  height: 20px;
  width: 400px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-2-admin {
  
  margin-top: 1rem;
  height: 20px;
  width: 300px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-3-admin {
  
  margin-top: 1rem;
  height: 20px;
  width: 300px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-btn-admin {
  margin-top: 1rem;
  height: 40px;
  width: 250px;
  background: $loader-gradient-color;
  border-radius: 25px;
}



//================================================
//                PRODUCT PAGE  
//================================================
.products-page-box {
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: 0.5fr 4fr 4fr 1fr 2fr;
  // gap: 10px;
  margin: 0.5rem 2rem;
  //min-height: 100vh;
}


//careful do not use aligt-items: center -> destroys the scrollbar
.submenu-box {
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin: 0;
  position: sticky;
  opacity: 0.9;
  top: 0;
  z-index: 1;
  background-color: $primary-color-body;
border-bottom: 1px solid rgb(148, 148, 148);
  h1 {
    margin: 0;
    align-self: center;
  }
}

.capsules-box-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping */
  margin-left: 2rem;
}

.capsules-box {
  display: flex;
  gap: 15px;
  //align-items: start;
  //padding: 1rem;
  
  justify-content: start;
  overflow-x: auto !important; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text wrapping */
  h2 {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    padding: 2rem 0;
    white-space: nowrap; /* Prevent text wrapping */
    cursor: pointer;
  }

}

.capsules-box {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.capsules-box::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.capsule-displayed {
  //text-decoration: underline;
  border-bottom: 2px solid;
}

.capsule-not-displayed {
  color: rgb(112, 112, 112) !important;
}

.products-box-flex-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 25px;
  justify-content: left;
  //padding: 0 4rem;
  margin-top: 2rem;
}

.product-box-flex-container {
  display: flex;
  flex-direction: column;
  width: calc(25% - 20px);
  
  // margin-right: 20px;
  margin-bottom: 20px;
 
}

.mini-display {
  //
}

.product-box-flex-container:nth-child(3n) {
  margin-right: 0;
  clear: both;
}

/* ProductPage.css */

.product-page-img {
  width: 90%; /* Set your preferred height */
  align-self: center;
  cursor: pointer;
 //background-color: rgb(194, 194, 194);
}

/* Add any other styling you need for your component */

.name-ref {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.prod-composition {
  font-style: italic;
  //padding-top: 3px;
}

.srp {
  font-size: 0.9rem !important;
    font-weight: 300 !important;
    font-style: italic;
}

.prod-lenght {
margin-left: 3px;
}

.info-product-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 0.5rem 0rem;
  //gap: 5px;
  // padding: 1rem;
  // border-radius: 20px;

  h2 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: 400;
  }

  p {
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0; 
  }
  
}

.capsule-title-product-page {
  font-size: 2rem;
  font-weight: 300;
  //text-decoration: underline;
  margin-bottom: 2rem;
  font-style: italic;
  text-align: start;
}

.collection-img-product-page {
  width: 50%;
}

//================================================
//                PRODUCT ICON PAGE  
//================================================
.wrapper-cataloue-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.catalogue-box {
  
  display: flex;
  //flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 1rem;
  margin-bottom: 3rem;
  max-width: 1200px;
  align-self: center;
}

.product-catalogue-img {
  max-width: 180px;
}


//================================================
//                CLIENT HOME PAGE  
//================================================
.client-home-page-box {
  margin: 0.5rem 2rem;
  min-height: 60vh;
}

.flex-container-client-page {

}

//================================================
//                ORDER PAGE 
//================================================ 

.order-container {
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: 0.5fr 4fr 4fr 1fr 2fr;
  // gap: 10px;
  margin: 0.5rem 2rem;
  min-height: 100vh;
}

.exit-box {
  margin:0;
  padding: 0;
  // background-color: #0d0e0e;
  // h2,h3,p,span {
  //   color: white;
  // }

}

.header-component{
  z-index: 1000;
  position: fixed;
  width: 76%;
  background-color: white;
}

.body-component {
  position: relative;
  top: 14rem
}

.wrapper-product-select {
  height: 100vh; /* Adjust the height as needed */
  overflow-y: auto;
}

.title-box {
  padding-top: 0.5rem;
  padding-left: 2rem;
  padding-right: 3rem;
}

.subtotal-upper-box {
  
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 3rem;
  border-bottom: 2px solid rgb(0, 0, 0);
}

.exit-container {
display: flex;

//gap: 10px;
justify-content: space-between;
align-items: center;

h2 {
  margin: 0;
}

p {
  font-size:15px;
  font-style: italic; 
  text-decoration: underline;
  font-weight: 400;
}
}

.sticky-top {
  opacity: 0.9;
  position: sticky;
  top: 0px;
  z-index: 10000;
  background-color: white;
  //border-bottom: 1px solid rgb(165, 165, 165);
}

.sticky-top-men-women {
  z-index: 10000;
  position: sticky;
  top: 0px;
  z-index: 1;
  // opacity: 0.9;
}

.at-top {
  background-color: $primary-color !important; /* Set the background color when at the top */
 
 h2 {
  color:white;
 }

}

.exit-2-container {
  display: flex;
gap: 10px;
justify-content: space-between;
align-items: center;
cursor:pointer;

}

.arrow-exit {
  width: 40px;
  cursor: pointer;
}

.arrow-exit-reverse {
  width: 40px;
  cursor: pointer;
  transform: rotate(180deg);
}

.horizontal-line {
  //border: 0.5px solid #000000; /* Color of the line */
  margin: 0; /* Adjust as needed */
}

//trick transition 0 -> auto
// .wrapper-capsules-order {
//   display: grid;
//   grid-template-rows: 0fr;
// }

// .wrapper-capsules-order > .capsule-name-flex-container {
//   overflow: hidden;
// }


.gender-box > h1 {
  font-size: 5rem;
  margin: 1rem 0;
}

.gender-box > h1 > span {
  font-size: 15px;
  font-style: italic;
}

// .testWrapper {
//    display: grid; //test
//    grid-template-rows: 0fr; //test
//    transition: grid-template-rows 500ms;
//    height: 100px;
// }

// .testWrapper:hover {
 
//   grid-template-rows: 1fr; //test
// }

.capsule-name-flex-container {
  
  display: flex;
  gap:10px;
  align-items: center;
  justify-content: start;
  margin-bottom: 0.5rem;

  p {
    font-size: 25px;
    cursor: pointer;
    //text-decoration: underline;
    font-style: italic;
    margin: 0;
  }

  span {
    color: $primary-color;
    font-weight: 400;
  }
}

.add-logo-container {
  display: flex;
  justify-content: space-between;
}

.collection-items-box {
  // overflow: hidden; //test
  margin-left: 2rem;
  cursor: pointer;

  li > span {
    font-size: 15px;
    font-style: italic;
    text-decoration: none;
  }

  li.underline-text span {
    text-decoration: none;
  }
}

.total-amount-box {
  margin-top: 2rem;
}

.total-amount-flex-container {
  display: flex;
  justify-content: space-between;
  margin-top:1rem;
 
}

.flex-container-amount-item-btn {
  display: flex;
  flex-direction: column;
  align-items: end;
  min-width: 400px;
}

.amount-item {
  margin: 0;
}

.amount-item > p {
  font-size: 1.5rem;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 10px;
}

.amount-item > span {
  display: block;
  font-style: italic;
  margin-bottom: 5px;
  
}

.flex-container-total-neto-discount {
  display: flex;
  gap: 10px;
  margin-bottom: 0.5rem;
  align-items: center;

  p {
    margin: 0;
    font-size: 1.4rem;
   
    font-weight: 500;
  }

  h3 {
    margin: 0;
    font-size: 1.4rem;
    text-decoration: line-through;
    font-weight: 500;
  }

  h2 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
    color: red;
    text-decoration: underline;
  }
  
}

.subtotal-crossed{
  text-decoration: line-through !important;
}

.box-prints {
  margin-top: 3rem;
}

.flex-container-prints {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 3rem;
  gap:10px;

  .prints {
    width: 250px;
    border-radius: 50%;
    border: 1px solid black;
  }
}

.flex-container-discounts-offer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 3rem;
  background-color: $primary-color-body;
  //padding: 1rem;

  h2 {
    margin: 0;
    font-weight: 300;
    font-size: 2.5rem;
  }

  h3 {
    margin: 0;  
    font-size: 1.2rem;
  }
}

.grid-container-how-to-proceed {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 30px;
  margin-top: 3rem;

  h2 {
    font-size: 2.5rem;
    font-weight: 300;
  }

  button {
    max-width: 400px;
  }
}

.color-themes {
  font-size: 5rem;
  font-weight: 300;
  margin-top: 3rem;
}

.grid-container-color-keys-box {
  padding: 0rem 0 8rem 0rem;
}

.grid-container-color-keys {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  margin: 2rem 0;
  //padding-bottom: 4rem;
  flex-wrap: wrap;
  
}

.flex-container-group-colors {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  

  h3 {
    font-size: 1.5rem;
    font-style: italic;
  }
}

.flex-container-colors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:20px;
  flex:1;
}

.rectangle {
  min-width: 100px; /* Adjust width as needed */
  min-height: 100px; /* Adjust height as needed */
  background-color: #ededed;
}

.r1-1 {
  background-color: #b1356f;
}

.r1-2 {
  background-color: #4b4b4b;
}
.r1-3 {
  background-color: #b5b5b5;
}
.r2-1 {
  background-color: #45b16f;
}

.r2-2 {
  background-color: rgb(59, 133, 40);
}
.r2-3 {
  background-color: #4b4b4b;
}
.r3-1 {
  background-color: #4596b1;
}

.r3-2 {
  background-color: #1e8aae;
}
.r3-3 {
  background-color: #4b4b4b;
}

.r4-1 {
  background-color: #8DCD9A;
}

.r4-2 {
  background-color: whitesmoke;
}
.r4-3 {
  background-color: #000000;
}

.r5-1 {
  background-color: #F2B780;
}

.r5-2 {
  background-color: #121C43;
}
.r5-3 {
  background-color: whitesmoke;
}

.r6-1 {
  background-color: #FFF2D9;
}

.r6-2 {
  background-color: #727992;
}
.r6-3 {
  background-color: #121C43;
}



//================================================
//                ProductCapsuleSelect 
//================================================ 
.product-component-box {
  //height: 100vh; /* Set a max height for the scrollable content */
  //overflow-y: auto; /* Enable vertical scrolling */
  padding: 1rem 2rem;

  h1 {
    text-align: center;
    font-size: 4rem;
    margin-top: 0;
    margin-bottom: 2rem;
    background-color: #ededed;
    font-weight: 300;
    padding: 2rem 0;
  }

  h2 {
    text-align: center;
  }
}

.box-style {
  //background-color: #ededed;
  //height: 2000px; /* Set a height for demonstration purposes */
}

.product-info-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  flex: 1;
}

.info-box-flex-container {
  display: flex;
  justify-content: start;
  
  align-items: center;
  gap: 10px;
  flex: 1;
 
}

.info-item {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.2rem;

  h2 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 1.5rem;
    font-weight: 400;
  }

  p {
    margin: 0px; 
  }
  
}

.change-confirmed {
  font-size: 12px;
  font-style: italic;
  cursor: pointer;
  padding-left: 0.25rem;
  text-decoration: underline;
}

.order-status-true {
  font-size: 12px;
  font-style: italic;
  cursor: pointer;
  padding-left: 0.25rem;
  text-decoration: underline;
  color: green;
}

.order-status-false {
  font-size: 12px;
  font-style: italic;
  cursor: pointer;
  padding-left: 0.25rem;
  text-decoration: underline;
  color: red;
}

.name-price-box {
  width: 100%;
  display: flex;
  justify-content: space-between;

    h2 {
      font-size: 1.5rem !important;
    }
  
}

.capsule-name {
  font-weight: 500;
  font-style: italic;
  text-decoration: underline;
  padding-top: 5px;
  cursor: pointer;
}


.info-img {
  max-height: 8rem; //same as the info together with img
}

.price-item {
  display: flex;
  gap: 10px;
  align-items: center;

  h2 {
    text-align: right;
  }
  p {
    color:rgb(0, 0, 0);
    font-weight: 300;
    
    font-style: italic;
  }
}

.size-box-flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  //gap: 25px;
  //width:90%;
  align-items: center;
 

}

.qty-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  p {
    text-decoration: underline;
    font-style: italic;
    font-size: 1rem;
  }
}

.subtotal-box {
  background-color: #ededed;
  padding: 2rem;
  margin-bottom: 5rem;
 
}

.flex-container-add-logo {
  display: flex;
  align-items: center;
  gap: 7px;

  h3 {
    color: rgb(14, 82, 155);
    // font-size: .8rem !important;
    font-weight: 500;
    margin: 0;
  }

  img {
    width: 20px;
  }
}


//================================================
//                DRAWERS
//================================================ 
//Product select
.product-capsule-side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26)
}

.product-capsule-side-drawer-right {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26)
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(-100%);
  transition: all 200ms;
  opacity: 1;
}

.slide-in-right-enter {
  transform: translateX(100%);
}

.slide-in-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-right-exit {
  transform: translateX(100%);
  transition: all 200ms;
  opacity: 1;
}
//================================================
//                BACKDROP
//================================================ 
.backdrop {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.65);
  z-index: 10;
  //transition: background 200s ease;

}

.blur-backdrop {
  z-index: 1000;
  background: rgba(0,0,0,0.35);
  backdrop-filter: blur(3px); /* Adjust the blur value as needed */
}

.loading-capsules-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center both horizontally and vertically */
  background-color: $primary-color;
  min-height: 100vh;
  
  gap: 15px;

  h1 {
    margin: 0;
    font-size: 5.2rem;
    color: white;
    font-weight: 300;
    text-align: center;
    width: 60vw; /* Set the width to 75% of the viewport width */
    /* Adjust other styles as needed */
    // opacity: 0; /* Start with opacity 0 */
    // transform: translateY(20px); /* Start slightly below */
    // transition: opacity 0.5s, transform 0.5s; /* Transition opacity and transform */
  }
}

.keen {
  width: 10vh;
}


//================================================
//                MODAL
//================================================ 


.modal {
  z-index: 100;
  position: fixed;
  top: 5vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 30px;
  max-height: 90%;
  overflow-y: auto; /* Allow vertical scrolling */
  
}

.modal__header {
  width: 100%;
  
  text-align: center;
  //background: #2a006e;
  color: white;

  h1 {
    margin-top: 2rem;
    font-weight: 400;
  }

}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

//================================================
//                    SUMMARY 
//================================================ 
.summary-card {
  position: relative;
  // border: 1px solid black;
  // margin: 1rem;
  // padding: 1rem 2rem;
  // border-radius: 40px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.delete-product {
  position: absolute;
  top:5%;
  right: 4%;
  margin: 0;
  color: gray;
  width: 25px;
}

.products-icons-setup-box {
  margin: 1rem 0;
  h3{
    //font-style: italic;
    margin:0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
  }
}

.products-icons-setup {
  padding: 0rem 0rem 0rem 0;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto; /* Allow vertical scrolling */
}

.list-icon-box {

  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  padding-top: 0.5rem;

  img {
    height: 20px;
    margin:0;
    cursor: pointer;
  }
}

.img-card {
  background-color: rgb(238, 238, 238);
  padding: 1rem;
border-radius: 25px;
}

// .img-bg-size {
//   width: 130%;
//   height: 130%;
// }

//================================================
//               ICONS - ORDER DETAILS
//================================================
.products-icons-order-details-setup-box {
  margin: 1.5rem 0;

  h1{
    //font-style: italic;
    //margin: 1rem 0;
    
    font-size: 2rem !important;
    font-weight: 500 !important;
  }

  // span {
  //   font-size: 1.5rem !important; 
  //   font-style: italic;
  //   margin-top: 1rem !important;
  // }
}

.products-icons-order-details-setup {
  padding: 0rem 0rem 0rem 0;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto; /* Allow vertical scrolling */
}



//================================================
//                ORDER STEPS
//================================================
.order-steps-box {
  margin: 0 2rem;
}

//================================================
//                ORDER CONFIRMATION
//================================================
.flex-container-order-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  justify-content: center;
  margin: auto; /* Center the container horizontally */
 // min-height: 30vh; /* Set a height if you want to center vertically as well */
  h3{
    margin: 0.5rem 0;
  }

}

.order-confirmation-text {
  width: 70%;
}

.flex-container-btn-confirmation {
  display: flex;
  justify-content: space-between;
  width: 70%;
  gap: 10px;
  align-items: center;
  
}

.btn-order-confirmation {
  //background-color: white;
  width: 100%;

}

.btn-order-confirmation-go-back {
  background-color: white;
}

.btn-order-confirmation-success {
  background-color: white;
  width: 100%;
}
//================================================
//                TECHS
//================================================
.box-container-tech-page {
  margin: 0.5rem 2rem;
  margin-bottom: 8rem;
  min-height: 100vh;

  h1 {
    font-size: 13rem; 
    font-weight: 300;
    width: 66%;
    margin: 2rem 0;
  }
}

.flex-container-techs {
  display: flex;
  flex-direction: column;
  //gap: 20px;
  //padding-left: 20%;
  padding-right: 0%;
  

  h2 {
    font-weight: 300;
    font-size: 2.5rem;
    margin: 0;
    min-width: 40%;
  }

  p {
    margin:0;
    font-size: 1.2rem;
  }

}

.flex-container-individual-tech {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding:1rem 0;
  padding-right: 0rem;
  gap: 10px;

  h2 {
    text-align: left;
    margin: 0;
  }
  p{
    margin: 0;
  }
}


//================================================
//                ORDER DETAILS
//================================================
.order-details-box {
  margin-left: 2rem;
  padding-right: 2rem;
  height: 100vh; /* Set a max height for the scrollable content */
  overflow-y: auto; /* Allow vertical scrolling */

  h1{
    font-size: 3rem;
    
  }

  h3,h2,h4,p{
    margin: 0
  }
  h2{
    font-size: 2rem;
    font-weight: 400;
  }
  h3{
    font-size: 1.2rem;
    font-weight: 300;
  }
  h4{
    font-size: 0.8rem;
    font-style: italic;
    text-decoration: underline;
    font-weight: 400;
    color: green;
  }
}

.order-details-header {
  position: sticky;
  top: 0px;
  background-color: white;
  opacity: 0.9;
  z-index: 10;
}

.flex-container-order-datails-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  // border-bottom: 2px solid black;
}

.flex-container-order-details-status-b1 {
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.flex-container-order-details-status-b2 {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;
}

.flex-container-order-details-products {
  //margin-top: 1rem;
  h1 {
    font-size: 2rem;
  }
  //style={{ fontSize: '1.5rem', fontStyle: 'italic' }}
  h3 {
    font-size: 1.5rem;
    font-style: italic;
    margin-top: 1rem;
  }
}

.flex-container-order-details-sizeQty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 0.3rem;
  border: 1px solid black;
  gap: 5px;
}

.flex-container-sizeQty {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.order-details-img-logo-container {
  display: flex;
  align-items: center;
}

.order-details-img-logo {
  width: 25px;
  margin: 0;
  padding: 0;
}

.horizontal-line-order-details {
  margin-bottom: 1rem;
}

.exit-order-details {
  display: flex;
gap: 10px;
justify-content: start;
align-items: center;
cursor:pointer;
margin-top: 2rem;
margin-bottom: 1rem;
}

.end-of-order-details {
  padding: 1rem;
  background-color: #efefef;
  text-align: center;
    margin-bottom: 2rem;
    h2 {
      font-size: 1rem;
    }
}

.size-qty-details {
display: flex;
gap:5px;
padding: 0.25rem;
span {
  margin: 0;
}
}

.above-zero-qty {
  background-color: black;

  p, span {
    color: white;
    font-weight: 400;
  }
  
}

//================================================
//                MOBILE MENU
//================================================
.flex-container-header-client-mobile {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: $primary-color-body;
  align-items: center;
 // border: 1px solid black;
  //margin: 0.5rem;
  margin: 0rem;

  p {
    margin: 0;
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: $primary-color-body;
  opacity: 0.9;
}

.flex-container-icons-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:5px;
}

//green badge
.bag-icon-container {
  position: relative;
  align-self: center;
  cursor: pointer;
  
}

.badge {
  position: absolute;
  top: 0px; /* Adjust this value to set the vertical position of the badge */
  right: 0px; /* Adjust this value to set the horizontal position of the badge */
  width: 12px;
  height: 12px;
  background-color: rgb(9, 203, 9);
  border-radius: 50%;
}


//Mobile
.icon-menu-mobile {
  width: 35px;
  cursor: pointer;
}
.icon-menu-mobile-bag {
  width: 25px;
 
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
 

}

.yes-products {
  content: '';
  background: #00b584;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 50%;
  margin-top: -0.6rem;
  right: 11px;
  border-radius: 50px;
}


//================================================  
//================================================
//================================================

//                ADMIN DESKTOPN 

//================================================  
//================================================
//================================================  

//Body structure for all - GRID 
.grid-admin-structure {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr;
  background-color: #f8f8f8;
  
}

//Admin Panel
.flex-container-admin-panel-desktop {
  display: flex;
  flex-direction: column;
  padding: 2rem; //this is forcing all to be inside the blue area but maybe not the best approach
  background-color: $admin-panel-desktop;
  gap: 25px;
  margin: 1rem 0 1rem 1rem;
  border-radius: 35px 0  0 35px ;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  

  h3 {
    color: white;
    font-size: 1rem;
    font-weight: 300;
    margin: 0.5rem 0;
  }
  p{
    color: white;
    font-size: 0.75rem;
    //margin-top: 2rem;
  }

  li {
    color: white;
    font-size: 1rem;
    margin: 1rem 0 1rem 1rem;
  }
}

.flex-container-menu {
  position: sticky;
  top: 0px;
}

.flex-container-gender {
  margin-left: 1rem;
  cursor: pointer;
}

.flex-container-capsules-open {
  display:block;
  cursor: pointer;
}

.flex-container-capsules-close {
  display: none;
  cursor: pointer;
}

.flex-container-icon-text {
  display: flex;
  gap: 10px;
  margin-top: 0.5rem;
  
  h3{
    cursor: pointer;
  }
}

.img-admin-panel {
  width: 20px;
  fill: white;
}

// .mobile-side-drawer-right {
//   display: none;
// }
.mobile-side-drawer-right {
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100 !important;
  height: 100vh;
  width: 30%;
  background: $primary-mobile;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  
}

//Backdrop mobile
.backdrop-mobile {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.65);
  z-index: 10;
  //transition: background 200s ease;
}

.flex-container-mobile-menu {
  z-index: 1000;
  background-color: $primary-color-body;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 2rem 0rem;
  height: 100vh;
  cursor: pointer;

  h2, h3 {
    color: $primary-color;
    padding: 0.5rem 1rem;
    padding-left: 3rem;
    margin: 0;
  }

  p {
    color: $primary-color;
  }

  .box-img-nav {
    margin-top: auto; /* Push the image to the bottom */
   
  }

  img {
    width: 100%;
    height: auto;
  }
}


.nav-item-highlight {
  background-color: $primary-color;
 
    color: white !important;
 
}

.edit-product {
  cursor: pointer;
}

.select-icon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="white" class="bi bi-caret-down" viewBox="0 0 16 16"><path d="M8.297 11.879l-4.096-4.098a1 1 0 0 1 1.415-1.414L8 10.081l3.384-3.614a1 1 0 0 1 1.415 1.414l-4.096 4.098a1 1 0 0 1-1.415 0z"/></svg>'); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;


}

.bottom-menu-box {
  //margin-top: 100%; /* Pushes the bottom menu to the bottom */
  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: space-around;
 // padding: 0 1rem;
  gap: 20px;

  h3,p {
    margin: 0;
  }

  
  //flex-direction: column;
}

.img-mobile-menu {
  width: 25px !important;
}

.hl-m-2 {
  margin-top: 2rem;
}

.bottom-menu {
  display: flex;
  gap: 5px;
  align-items: center;

  p{
    font-style: italic;
  }

  // img {
  //   width: 20%;
  // }
  // h3 {
  //   font-size: 0.8rem;
  //   font-style: italic;
  //   font-weight: 400;
  //   padding: 0;
  //   margin: 0;
  // }
  // p {
  //   font-size: 0.8rem;
  //   font-style: italic;
  //   font-weight: 400;
  //   padding: 0;
  //   margin: 0;
  // }
  
}

//================================================
//                COLLECTIONS PAGE
//================================================
.flex-container-general-admin-page {
  display: flex;
  flex-direction: column;
  background-color: white;
}

//this one dissappears in mobile
.flex-container-header-admin-desktop {
  display: flex;
  //height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0rem 1rem 0 1rem;

  p{
    font-size:13px;
    font-style: italic;
    cursor: pointer;
  }
}

.flex-container-header-admin-desktop-adds {
  display: flex;
  gap: 15px;
}

.flex-container-header-admin-mobile {
  display: none;
}

.display-none {
  display: none;
}

.mb-1 {
  margin-bottom: 1rem;
}

//this one completely changes in desktop
.card-collection-page {
  background-color: $secondary-color-admin;
  margin: 1rem 2rem 1rem 0rem;
  border-radius: 0 35px 35px 0 ;
  min-height: 100vh;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  
}

.flex-container-available-collection-number-of-products {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-top: 1.5rem;
  align-items: center;

  h2 {
    margin: 0;
    padding-bottom: 0.5rem;
    font-size: 2rem;
  }
  span, p {
    font-style: italic;
    margin: 0;
    padding-bottom: 1rem;
  }
}

.flex-container-available-collection {
  display: flex;
  gap:5px;
  align-items: center;

  p {
    font-style: italic;
    margin: 0;
  }
}

.no-products-message {
  p {
    font-style: italic;
    //margin: 0;
  }
}

.floating-mobile-menu {
  display: hidden;
  z-index: 100000000;
}

.floating-mobile-menu h3 {
  margin: 0;
  font-size: 14px;
  color: white !important;
  
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 5%;
  width: 40px;
  height: 40px;
  background-color: black;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
  opacity: 0.85;
  z-index: 100000000;
}

.arrow-up {
  color: white;
  font-size: 24px;
  line-height: 0;
  z-index: 100000000;
}

//================================================
//                    ORDERS 
//================================================ 
.order-admin-container {
  p {
    margin: 0;
    font-size: 1rem;
  }

  h4 {
    margin: 0;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 400;
    color: green;
  }

  h3 {
    margin: 0;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 400;
   
  }
}

.edit-order-admin {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;

  p {
    font-style: italic;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }

  
}

.sheet-delete-order-admin{
  display: flex;
  justify-content: space-between;
  gap: 5px;

  p {
    font-style: italic;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
  }

  
}

.flex-container-collections-orders {
display: flex;
flex-direction: row;
gap: 10px;
cursor: pointer;

p {
  margin: 0;
}

}

//================================================
//                    DASHBOARD 
//================================================ 
.flex-container-dashboard {
  display: flex;
  gap: 25px;
  flex: 1;
  margin: 2rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.dashboard-card {
  border: 1px solid rgb(152, 152, 152);
  box-shadow: 0 2px 8px rgba(69, 69, 69, 0.1);
  border-radius:35px;
  width: 400px;
  height: 400px;
  background-color: white;

}

//================================================
//                    CLIENT 
//================================================ 
.clients-box {
  padding: 2rem;
  
}

.add-client {
  margin-bottom: 2rem;
  font-size:13px;
    font-style: italic;
    cursor: pointer;
}


.flex-container-clients-board {
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex: 1;
  align-items: center;

  h2,h3,p,span {
    margin: 0;
  }

  .client-title {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 300;
  }
  .client-info {
    padding-left: 1rem;
    margin-bottom: 0.2rem;
    font-size: 1.2rem;
  }
  
}
.client-card {
  border: 1px solid rgb(152, 152, 152);
  box-shadow: 0 2px 8px rgba(69, 69, 69, 0.1);
  border-radius:25px;
  width: 100%;
  //height: 200px;
  //background-color: white;
  
}

.client-card-info-box {
  padding: 1rem ;
}

.client-header-info-box {
  display: flex;
  justify-content: space-between;

  h2{
    font-weight: 400 !important;
  }
}



//================================================
//                    COLLECTIONS PAGE 
//================================================ 
.flex-container-collections {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.flex-container-title-selector {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  //margin: 2rem 0;
  align-items: center;

  h2 {
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0;
    font-style: italic;
    
  }
}

.flex-container-collection-products {
  display: flex;
  flex-direction: column;
  //margin: 2rem;
}

.edit-product-admin {
  display: flex;
  justify-content: space-between;
  text-align: right;
  
  align-items: center;
  gap: 10px;

  p {
    font-style: italic;
  text-decoration: underline;
  }
}

.edit-product {
  // display: flex;
  // justify-content: space-between;
  // gap: 5px;
}

.edit-and-stock-box {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.order-selector {
  //max-width: 3rem;
  width: 3rem;
  text-align: start;
  //font-size: 13px;
  height: 2.5rem;
  border: none
}

.capsules-collection-list-box {
  padding: 1rem;

  h2{
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
}

.capsules-collection-list {
  
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  
  h3 {
    font-size: 0.8rem;
    margin: 0;
    font-style: italic;
  }
}

//products page 
.downloads-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  p {
    font-size: 0.8rem;
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
  }
}



//================================================
//              MODAL NEW COLLECTION 
//================================================ 

.flex-container-create-new-collection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px
}

.color-white {
  background-color: white !important;
}

.btn-accept-create-new-collection {
  padding: 10px;
  max-width: 300px;
  border: 1px solid black;
}

.btn-accept-create-add-capsule {
  padding: 12px;
  max-width: 350px;
}

.input-add-capsule {
  padding: 10px;
  max-width: 330px;
  background-color: white;
}

.btn-accept-updated-product {
  padding: 15px;
  width: 100%;
  border: 1px solid black;
}


//================================================
//              MODAL EDIT PRODUCT 
//================================================ 

.flex-container-edit-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4rem;
  //gap: 15px;
}

.flex-container-edit-product-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
}

.edit-product-input {
  padding: 10px;
  max-width: 400px;
  height: 1.2rem;
  background-color: white;
}

.tech-group-edit {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  max-width: 420px;
  flex-wrap: wrap;
  margin: 10px 0;

  .tech-item {
    position: relative;
    display: flex;
    align-items: center;

    p {
      margin: 0;
      border: 1px solid black;
      border-radius: 50px;
      padding: 5px 10px;
      background-color: $primary-color;
      color: white;
    }

    h3 {
      font-style: italic;
    }

    .delete-tech-button {
      background: none;
      border: 1px solid black;
      cursor: pointer;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      padding: 2px;
      background-color: rgb(255, 255, 255);
      color: rgb(0, 0, 0);
    }
  }
}

.edit-product-tech-selector {
    border: 1px solid black;
      border-radius: 50px;
      padding: 5px 10px;
      background-color: white;
      color: rgb(0, 0, 0);
}


//================================================
//              MODAL CREATE NEW COLLECTION 
//================================================ 
.selector-create-collection-gender {
  background-color: white;
  padding: 10px;
  width: 350px;
}

///================================================
//             NEW IMAGE BIG SCALE
//================================================ 
.ibg-logo {
  margin: 1.5rem;
}

.ibg-box {
  margin: 2rem 2rem;
  margin-bottom: 4rem;
  }

  

.ibg-card-box {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  //margin-bottom: 4rem; //keep footer at distacnce
  

  h1,h2,h3,p{
    margin: 0;
  }
}

.image-block {
  width: 60%; //in connection with info-block
  height: auto;
  align-self: center;
 
}

.info-block{
  width: 40%;//in connection with image-block
  //padding-right: 2rem;
}

.ibg-title {
  display: flex;
  flex-direction: row;
  align-items:end;
  justify-content: space-between;
   flex-wrap: wrap;

  h1 {
   // margin-bottom: 0.5rem;
   //font-size: 2rem;
  }
  h2 {
    margin-bottom: 0.5rem;
  }

}

.ibg-italic {
  font-style: italic;
  font-weight: 300;
  margin-top: 0.5rem !important;
  
}

.ibg-name {
  font-size: 1.5rem;
  font-weight: 400;
}

.ibg-features {
  h2 {
    margin: 1rem 0;
  }
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p{
    font-style: italic;
    margin-bottom: 0.2rem;
  }
}

.ibg-name-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.available-sizes {
  margin-top: 2rem;

}

.ibg-header {
  display: none;
}

.ibg-content {
  padding: 0; //to contrarest from the modal classes
}

.product-techs-box {
margin-top: 4rem;
padding-left: 2rem;

// h1 {
  
//   //margin-bottom: 0.5rem;
//   font-weight: 300;
//   font-size: 3rem;
// }

p {
  font-size: 1.2rem;
  font-style: italic;
  margin: 0
}


}

.product-techs {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap:30px;
  margin-top: 2rem;

  h2 {
    font-size: 1rem !important;
  }

  
}

.individual-tech {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  gap: 10px;

  h2 {
    font-weight: 300;
    font-size: 1.2rem;
    margin: 0;
  }
  
}

.img-tech {
  width: 30px;
}

//================================================
//              IMAGE BIG SCALE
//================================================ 
.flex-container-image-big-scale {
display: flex;
flex-direction: column;
gap:10px;
}

.flex-container-title-image-big-scale {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  h1{
    font-weight: 300;
    font-size: 3rem;
    margin: 0;
  }
}

.flex-container-image-big-scale-info {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;

  h2 {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0 0 0.5rem 0;
  }
}

.box-container-img-big-scale {
  display: flex;
  justify-content: center;
}

.image-big-scale {
  width: 40%;
}

.img-close {
  width: 30px;
}

.img-big-scale-modal{
  background-color: $primary-color-body;
  z-index: 10000;
  width: 100%;
  top: 0%;
  left: 0%;
  bottom: 0%;

  max-height: 100%;
  border: none;
  border-radius: 0;
  
}

//upload images
.choose-img-box {
 // width: 30%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
}

.add-new-image {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.image-container {
  position: relative;
  display: inline-block;
}

.close-icon {
  position: absolute;
  top: 5px; /* Adjust the top position as needed */
  right: 5px; /* Adjust the right position as needed */
  width: 20px;
  cursor: pointer;
}

.circle {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px; /* Adjust the size of the circle */
  height: 30px;
  border-radius: 50%; /* Ensures a round shape */
  background-color: #ededed; /* Set the background color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-number {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: black;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  

  p{
    color: white;
    font-size: 12px;
  font-weight: bold;
  }
}

.logo-added {
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
}

//change product capsule
.flex-container-change-capsule {
  display: flex;
  align-items: center;
  justify-content: space-around;

  h2{
    margin: 0;
  }
}

//test
/* YourComponent.css */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.loader span {
  display: inline-block;
  width: 10px;
  height: 20px;
  background-color: #999;
  margin: 0 2px;
  animation: wave 1s infinite;
}

@keyframes wave {
  0%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-15px);
  }
}


//================================================
////// ADMIN DASHBOARD PAGE
/////================================================
.dashboard-page-box {
  padding: 2rem;
  
}

//special colors
.men-color-dash {
  background-color: #00b584 !important;
  border: 1px solid #00b584 !important;
}

.women-color-dash {
  background-color: #bf836c !important;
  border: 1px solid #bf836c !important;
}

.dashboard-page-container-choose-collection {
  margin-bottom: 3rem;
  li {
    cursor: pointer;
   
  }

  li:hover {
    font-size: 1.6rem;
    
  }
}

//cards
.card {
  border: 1px solid rgb(152, 152, 152);
  box-shadow: 0 2px 8px rgba(69, 69, 69, 0.1);
  border-radius:35px;
  height: 250px;
  z-index: 1
}

.c-long {
  height: 500px + 20px;
} 

.c-short {
  height: 150px;
} 

//////////////// TITLE//////
.grid-container-title-admin-dash {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
   gap: 30px; /* Adjust the gap as needed */
   margin-bottom: 60px;
}

.title-admin-dash {
  border: 1px solid black; /* Optional: Add borders for better visibility */
 
  text-align: center;
  //height: 300px;
  //width: 300px;
  background-color: $primary-color;

  h2,p {
    color: white;
    text-align: left;
    margin: 0;
   }

   h2 {
    margin-bottom: 0.5rem;
   }
}

.title-admin-dash-info {
  padding: 2rem;
}

.title-admin-dash:nth-child(1) {
  grid-column: span 3; /* Second row, first column takes 2/3 of the width */
}

.date-admin-dash {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  

  h2,p {
    text-align: right;
    margin: 0;
    width: 100%;
   }

   p {
    text-align: bottom;
   }
  
}

.collection-admin-dash {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;

  p{
    margin: 0.5rem 0;
    font-style: italic;
  }
}

.btn-admin-dash {
  background-color: $primary-color-body;
  color: black;
  margin: 0;

  &:hover {
    background: rgb(223, 223, 223);
  }
}

//////////////// STATISTICS


.stats-dash-box {
  margin-bottom: 4rem;
}

.stats-title-gender {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 0.5rem;

  h2 {
    font-weight: 300;
    margin:0;
  }

  p, span {
     margin: 0; 
    }
}

.stats-confirm-icons {
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 20px;
  }
}

.c-stats-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h1,h2,p,span {
    color: white;
    margin: 0;
    text-align: start;
  }


  h2 {
    font-weight: 300;
    font-size: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
  }
  span {
    font-style: italic;
    margin-left: 10px;
  }
}

.c-stats-1-info {
  padding-left: 1rem ;
  padding-bottom: 2rem;
  p {
    font-size: 1rem;
  }
}

.c-stats-total {
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  span {
    margin: 0;
  }
}

.c-stats-2, .c-stats-3 {
 
  overflow-y: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */
  h2,h3,p {
    margin: 0;
  }

  h2 {
    margin: 0;
    font-weight: 300;
    font-size: 2rem;
    padding-top: 1rem ;
    padding-left: 1rem ;
    text-align: left;
  }

  p, h3 {
    margin-top: 0.5rem;
  }

  h3 {
    font-weight: 500;
  }

}

.c-stats-2::-webkit-scrollbar { 
display: none;  /* Safari and Chrome */
}

.c-stats-2-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem ;
  padding-right: 1rem ;
  
}

.c-stats-2-title {
  position: sticky;
  top: 0px;
  background-color: $primary-color-body;
  border: 25px;
}

.c-stats-men {
  height: 100%;
  display: flex;
 // align-items: center; /* Center vertically */
  flex-direction: column;
  justify-content:center; /* Center horizontally */
  
  h1 {
    text-align: center;
    
  }
}

.c-stats-4-part-1, .c-stats-4, .c-stats-6 {
  height: 250px;
}

.c-stats-4-part-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid gray;
  
  h1 {
    margin: 0;
    
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    font-size: 2rem;
    text-align: left;
    //width: 30%;
  }
}

.c-stats-4-part-1-category-info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  align-items: start;
  padding-left: 1rem;
  padding-bottom: 2rem;

  h2,h3 {
    margin: 0;
  }

  h2 {
    font-size: 3rem;

  }
}

//c-stats-4
.c-stats-4 {
  //border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  
  overflow-y: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */

  h1 {
    margin: 0;
    
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    font-size: 2rem;
    text-align: left;
    width: 30%;
  }

  p{
    text-align: left;
    
  }
}

.c-stats-4::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
  }

.c-stats-4-products-box { 
  padding: 1rem;

  h3,h2,p {
    margin: 0;
  }
}



.c-stats-4-product {
  //width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 5px 0;
  border-bottom: 1px solid rgb(185, 185, 185);


  h2 {
    text-align: left;
    font-size: 1rem;
  }
  h3 {
    font-size: 1rem;
   
    text-align: left;
    font-weight: 400;
  }
  p {
    font-size: 0.75rem;
    margin-top: 5px;
    text-align: left;
  }
}



.c-stats-4-p-info {
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 10px;
  

  img {
    width: 50px;
  }
}

.c-stats-4-p-position {
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  gap: 22px;

}

.position-circle {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  background-color: black;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: bold;
}


.c-stats-6 {
 
  background-color: $primary-color-body !important;
  border: 2px solid $primary-color !important;

  h1 {
    color: $primary-color !important;
  }
  
}

//grid done for the stats component used in the dashboard page
//done by % instead of number of columns
.grid-container-stats-admin-dash {
  display: grid;
  //grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  gap: 10px; /* Adjust the gap as needed */
   
}

.grid-item-stats-admin-dash {
  text-align: center;
 
  background-color: $primary-color-body;
}

//////////////// //////////////// //////////////// //////////////// //////////////// 
////////////////              PRODUCTION           //////////////// //////////////// 
/// //////////////// //////////////// //////////////// //////////////// ////////////
.production-admin-gender-box {
  display: flex;
  justify-content: space-between;
  background-color: $primary-color-body;
  
  position: sticky;
  top: 0;
  //opacity: 0.9;
  z-index: 2;

  h1 {
    margin: 0;
    
  }
  p {
    cursor: pointer;
  }
}

table {
  background-color: rgb(201, 201, 201);
  width: 100%;
  border-collapse: collapse; //remove weird borders

}

caption, th,td {
  padding: 1rem;
}

caption {
  text-align: left;
}

th:first-child {
  text-align: left;
}

td:not(first-child) {
  text-align: center;
  border: 1px solid rgb(179, 179, 179)
}

caption {
  font-size: 2rem;
 //border: 2px solid $primary-color;
  background-color: $primary-color;
  color: #ffffff;
}

th {
  font-weight: 500;
  font-size: 1rem;
  background-color: rgb(108, 108, 108);
  color: white;
  position: sticky;
  top:3.6rem;
  //opacity: 0.9;
}

td:last-child {
  font-weight: 500;
}

td:first-child {
  width: 50%; //for the product description
}

/* Set the rest of the td elements to have equal width */
td:not(:first-child) {
  width: calc((50%) / (7 - 1)); //for the sizes and total
}

tr:nth-of-type(2n) {
  background-color: rgb(218, 218, 218);
}

.production-admin-table {
  
}

.production-table-product-cell-box {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 10px;
}

.production-table-product-cell {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  h2,h3,p {
    margin:0;
  }

  h2 {
    font-size: 1rem;
  }
  p{
    font-size: 0.75rem;
  }
}

.production-admin-sort-by {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 2rem 0rem;
  border: 1px solid black;
  border-radius: 25px;

  h2 {
    margin: 0;
    cursor: pointer;
  }
}



.production-admin-proshops-box {
  padding-bottom: 2rem;
}

.sorted-by-selected {
  font-weight: 500;
  font-size: 2rem;
}

.sorted-by-not-selected {
  font-weight: 200;
  font-size: 2rem;
}

.production-admin-proshops {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.btn-info-production-orders {
  width: auto;
  padding: 0.2rem 1rem;
  margin: 0;
}

.download-sort-products {
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
  
}

//////////////////////////////////////////////////
/// CUSTOM DESIGN
///////////////////////////////////////////////////
.custom-design-page-box {
  margin-bottom: 8rem;
}

.custom-design-container {
  // background-image: url('/images/designStyle.png');
  // width: 100%;
  // height: 500px;
  // background-position: center;
  // background-size: cover;
  //backgroundAttachment: 'fixed',
  position: absolute;
  
  bottom: 15%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
    justify-content: start;
    align-items: start;
    
    padding-left: 2rem;

  h1 {
    color: white;
    font-size: 13rem;
    max-width: 200px;
  }

  h2 {
    color: white;
    font-weight: 300;
  }
}

.custom-design-box {
  // position: sticky;
  // top: 0;
  // z-index: -1;
}

.login-to-redirect-error {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin: 0 2rem;

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-style: italic;
  }
}

//============ lost items
.finder-box-order-page {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;

  p {
    cursor: pointer;
  }
}

//client admin
.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.order-info {
  flex: 1;
  
}

.order-status-client-page {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.status-circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: 0.5rem;
}

.status-true {
  background-color: green;
}

.status-false {
  background-color: red;
}

.status-blue {
  background-color: blue;
}

.status-gray {
  background-color: gray;
}

.container-point-system {

  display: flex;
  justify-content: space-between;
  align-items: end;

  h3 {
    font-weight: 500;
    font-size: 14px;
  }
  li {
    
    font-size: 13px;
  }

  p{
    margin: 0;
    font-style: italic;
    text-decoration: underline;
  }

  margin-top: 2rem;
  margin-bottom: 2rem;
}

//IS CHOOSEN COLLECTION COMPONENT
.choose-collection-admin-container {
  margin: 2rem 0;

  li {
    cursor: pointer;
  }
}

//ADD STOCK
.add-stock-box {
  margin: 3rem;
}

.stock-available-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3, span {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
  }
}

/////// STOCK CLIENT PAGE
.available-stock-client-page {
  margin: 3rem;
}

.available-stock {
  margin: 2rem;

}

.capsule-title-stock {
  //text-decoration: underline;
}

.available-stock-hr {
  color: #e1e1e1;
}

.header-stock-available {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
    font-style: italic;
  }

  p {
    font-size: 1.2rem;
    margin: 0;
    text-decoration: underline;
    font-style: italic;
   cursor: pointer;
  }
}

.flex-container-collection-products {
  position: relative;
  padding-bottom: 1rem; /* Make space for the floating button */
}

.floating-btn {
  position: fixed;
  bottom: 6rem;
  right: 20px;
  z-index: 1000;
  width: 30%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.floating-btn:hover {
  background-color: #2f2f2f;
  color: white;
}




//================================================
//================================================  
//================================================

//                MEDIA QUERIES 

//================================================  
//================================================  
//================================================

//================= MOBILE PHONE =================
//Portrait change back to 850
@media ( max-width: 850px ) {

  //==============================================
  //Logo
  .logo {
    width: 100px; /* Set the width */
  transform: scale(0.65);
    //height: 200px; /* Set the height */
  }

  //================================================
//                HOME PAGE PLUS
//================================================ 
//block 1
.flex-container-home-page-block-1 {
  padding: 15rem 0rem;
  //padding: 8em 2em;

  h1,h2,p {
    color: white;
  }

  h1 {
    margin: 0;
    font-size: 4.5rem;
    max-width: 66%;
  }

  h2 {
    font-weight: 300;
    margin: 0;
    font-size: 1.5rem;
  }
}

.flex-container-home-page-title {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.flex-container-new-collection-items {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  //padding-right: 5rem;
  align-items: center;
}

.home-page-btn-download {
  margin: 0;
  padding: 0;
}

.flex-container-home-page-block-2 {
  display: flex;
  flex-direction: column;
  padding: 6em 1em 2vh;
}

.flex-container-home-page-highlights {
  flex-direction: column;
  h1{
    font-size: 4.5rem;
  }

  p{
    font-size: 0.7rem;
  }
}

.flex-container-individual-highlight {
  flex-direction: column;
  padding: 0;
  padding-top: 1.5rem;
  align-items: start;
  
}

.flex-container-individual-highlight-reverse {
  flex-direction: column-reverse;
  padding: 0;
  padding-top: 1.5rem;
  align-items: start;

  h2 {
    text-align: left;
  }
}

.flex-container-image-description-block {
  flex-direction: column;
  //padding: 0;
  padding: 2rem 0;
  gap: 50px;
}

.flex-container-description {
  h2 {
    padding-top: 1rem;
    font-size: 2rem;
  }
}

.img-home-page {
  max-width: 100%;
}

.flex-container-highlights-description-block {
  padding: 0rem;

  h2{
    font-size: 2rem;
  }
  p {
    font-size: 1.2rem;
  }
}


  //==============================================
  //LOG IN PAGE

  .log-in-form, .log-in-info {
    width: 300px; //equal as button and input so all in the center
  }

  .form-container > h1 {
    font-size: $large-size;
  }

  .input-log-in-username, .input-log-in-password {
    padding: 1rem 0.5rem;
    width: 282px; //300-18
  }

  .button-accept {
    padding: 0.5rem;
    width: 300px;
  }
  //==============================================
  //HOME PAGE
  //This grid is transformed to flex so there is no so much space around the bottoms
  .grid-container-create-brochure {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    ;
  }

  .offer-title {
    font-size: 4rem;
  }

  .other-work-box {
    padding:0rem 0rem;
    padding-bottom: 3rem;
  }

  .grid-container-other-work {
    grid-template-columns: 1fr;
    grid-template-rows: 3fr;
    place-items: center;
  }

  // .info-flex-container {
  //   max-width: 300px;
  //   font-size: 3rem;
  //   margin-top: 2rem;
  // }

  .info-flex-container > h1 {
    font-size: 4rem;
  }

  .button-accept {
    padding: 1rem 0.5rem;
    width: 300px;
  }

  .button-info {
    padding: 1rem 0.5rem;
    width: 300px;
  }

  .grid-container-all-season {
    //display: flex;
    //flex-direction: column-reverse;
    //gap: 40px;
    //flex:1;

    button {
      width: 80%;
      margin-right: 1rem;
     
    }

  
  }

  .official-suppliers-title {

    font-size: 4rem;
  
}

  .flex-container-all-season-info {
    align-items: end;
    flex:1;

    h1 {
      font-size: 4rem;
      
    }
    
  }

  .flex-container-official-supplier {
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
    padding-left: 0;
    align-items: start ;

    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1rem;
    }
  }

  .solheim-reverse{
    flex-direction: column-reverse;
  }

  .img-the-solheim {
    margin-top: 2rem;
  }

  .img-the-open {
    margin-top: 2rem;
    place-items: center;
    
  }

  .flex-container-official-supplier img {
    align-self: center;
  }

  .flex-container-client-status {
    flex-direction: column;
    align-items:start;
    gap: 7px;
  }

  //==============================================
  //ORDER PAGE

  .title-box > h1 {
    font-size: 2rem;
  }

  .gender-box {
    h1{
      font-size: 3rem;
      margin: 1rem 0;
    }
    h1 > span {
      font-size: 0.75rem;
    }
  }
  
  .capsule-name-flex-container {
    
    p {
      font-size: 1rem;
    }
    span {
      font-size: 10px;
    }
  }

  .total-amount-flex-container {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .flex-container-amount-item-btn {
    align-items: center;
    

    p {
      font-size: 1.5rem;
      
    }
  }

  .flex-container-total-neto-discount{
    //flex-direction: column;
    gap: 5px;
  }

  .flex-container-discounts-offer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 3rem;
    background-color: $primary-color-body;
    padding: 1rem;
  
    h2 {
      margin: 0;
      font-weight: 300;
      font-size: 1.5rem;
    }
  
    h3 {
      margin: 0;  
      font-size: 1rem;
    }
  }

  .grid-container-how-to-proceed {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 4rem;
    

    h1 {
      font-size: 2rem;
      margin: 0;
    }
  }

  .grid-container-color-keys {
    flex-direction: column;
  }

  .color-themes {
    font-size: 4rem;
  }

  .header-component{

    width: 84%;
  
  }

  //==============================================
  //DRAWERS =====================
  .product-capsule-side-drawer {
    width: 100%;
  }

  .product-capsule-side-drawer-right {
    width: 100%;
  }

  .product-component-box {
    h1 {
      font-size: 2rem;
      margin: 0;
    }
  }

  .exit-container {
    flex-direction: column;
    align-items: start;

    h3 {
      font-size: 15px;
      margin-bottom:  0 0 0 0;
    }
  }

  .exit-2-container {
    p {
      font-size: 15px;
    }
  }

  .info-box-flex-container  {
    img {
      //max-width: 60px;
      //background-color: $primary-color-body;
    }
    h2{
      font-size: 1rem;
      margin: 0;
      padding: 0;
      text-align: left;
    }
    p{
      font-size: 0.75rem;
    }
  }

  .info-img {
    max-height: 7rem; //same as the info together with img
  }

  .price-item {
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 12px;
    }
  }

  .qty-flex-container {
    h3 {
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
  }

  .subtotal-box {
    padding: 1rem 0;
    margin-bottom: 5rem;
    h2 {
      font-size: 1rem;
    }
  }

  //=======================================
  //==== BTN QTY
  .size-btn-box {
    //flex: 0 0 100%;
    p {
      font-size: 15px;
    }
  }
  .btn-flex-container {
    gap: 5px;
    //flex: 1;
    
    //margin-right: 5px;

    p{
      font-size: 12px;
    }
  }

  .add-quantity-button {
    width: 30px; /* Set a fixed width */
    height: 30px; /* Set a fixed height */
  }

  .add-quantity-button span {
    line-height: 20px; /* Centers the plus sign vertically */
  }

  //Modal
  .modal {
    width: 100%;
    left:0%;
    top: 4vh;
    

    // .modal {
    //   z-index: 100;
    //   position: fixed;
    //   top: 5vh;
    //   left: 10%;
    //   width: 80%;
    //   background: white;
    //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    //   border-radius: 30px;
      
    // }


  }

  //Product capsule select
  .size-box-flex-container {
    justify-content: start;
    flex-wrap: wrap;
    gap: 0px 35px;
  }
  .size-btn-box{
    width: 500px;
  }

  //Order confirmation
.flex-container-order-confirmation {
  width: 90%;

  h3{
    margin: 0.5rem 0;
  }
}

.order-confirmation-text {
  width: 100%;
}

.flex-container-btn-confirmation {
  flex-direction: column;
  width: 100%;
  gap:0px;
}

.btn-order-confirmation {
  width: 100%;
}

.btn-order-confirmation-go-back {
  width: 100%;
}

  //Brand highlights
  .grid-container-highlight-item {
  
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    
  }
  
  .grid-container-structure-item {
   
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  
  }

  ////////////////////////////////////////////////
  /// ADMIN
  .grid-admin-structure {
    grid-template-columns: 5fr;
    grid-template-rows: 1fr;
   
  }

  .flex-container-admin-panel-desktop {
    display: none;
  }

  //this one completely changes in desktop
.flex-container-header-admin-desktop {
  //display: none;
}

//this one completely changes in desktop
.card-collection-page {
  background-color: $secondary-color-admin;
  margin:0rem;
  border-radius: 0;
  min-height: 100vh;;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  
}

.icon-user-menu-mobile {
  width: 30px;
}

.flex-container-mobile-menu {
  z-index: 1000 !important;
  display: flex;
  flex-direction: column;
  gap:15px;
  //padding: 2rem;
  cursor: pointer;
  //align-items: center;

  h2, h3{
    color: $primary-color;
    padding: 0.5rem 1rem;
    padding-left: 3rem;
    margin: 0;
  }

  p {
    color: $primary-color;
  }
}

.flex-container-header-admin-mobile {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: $admin-panel-desktop;
  align-items: center;

  p {
    margin: 0;
  }
}

.floating-mobile-menu {
  position: fixed;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  border-radius: 50px;
  padding: 10px 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
  opacity: 0.85;
}

.floating-mobile-menu h3 {
  margin: 0;
  font-size: 14px;
  color: white !important;
}

.scroll-to-top {
  position: fixed;
  bottom: 3%;
  right: 3%;
  transform: translateX(-50%);
  margin-left: 70px; /* Adjust this value to ensure spacing between the buttons */
  width: 40px;
  height: 40px;
  background-color: black;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s;
  opacity: 0.85;
}


.arrow-up {
  color: white;
  font-size: 24px;
  line-height: 0;
}


.mobile-side-drawer-right {
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100 !important;
  height: 100vh;
  width: 80%;
  background: $primary-mobile;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  z-index: 10;
}


//Backdrop mobile
.backdrop-mobile {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.65);
  z-index: 10;
  //transition: background 200s ease;
}

//Dashboard
.flex-container-dashboard {
  flex-direction: column;
}

.dashboard-card {

  width: 300px;
  height: 300px;


}

.dashboard-page-container-choose-collection {
  h1 {
    font-size: 2rem;
  }
  margin-bottom: 3rem;
  li {
    cursor: pointer;
   
  }

  li:hover {
    font-size: 1.6rem;
    
  }
}



//Collections page

.flex-container-available-collection-number-of-products {
  //flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-top: 1rem;
  gap: 10px;

  h2 {
    margin: 0;
    padding: 0rem;
    font-size: 1.4rem;
  }
  p, span {
    font-style: italic;
    margin: 0;
    padding-bottom: 0rem;
  }

  span {
  font-size: 0.8rem;
  }

}

.flex-container-title-selector{
  flex-direction: column;
}

.styled-select {
  background-color: $secondary-color-admin;
}

//Modal edit product
.flex-container-edit-product {
margin:0 0rem;
padding: 0 1rem;
}

.flex-container-edit-product-input {
  flex-direction: column;
  align-items:start;
  h2,p {
    font-size: 1rem;
  }
}

.edit-product-input {
  padding: 0.5rem 0.5rem;
  width: 90%;
}

.edit-product-input::placeholder {
  font-size: 1rem;
}

.edit-product-input input {
  font-size: 1rem;
}

.modal__header h1 {
  font-size: 2rem;
}

.btn-accept-updated-product {
  padding: 1rem 0rem;
  width: 100%;
}

//================================================
//                MOBILE MENU
//================================================
.header {
display: none;
}

.flex-container-header-client-mobile {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: $primary-color-body;
  align-items: center;
  //border: 1px solid black;
  //margin: 0.5rem;
  

  p {
    margin: 0;
  }
}



//================================================
//                ORDER DETAILS
//================================================

.order-details-box {
  margin-top: 0;
  margin-left: 1rem;
  
  h1{
    font-size: 3rem;
   
  }

  h2,h4,p{
    margin: 0
  }
  h2{
    font-size: 1.5rem;
    font-weight: 400;
  }
  h3{
    font-size: 1rem;
    font-weight: 300;
  }
  h4{
    font-size: 0.8rem;
    font-style: italic;
    text-decoration: underline;
  }
}

.flex-container-order-datails-info {
  flex-direction: column;
  gap: 20px;
  align-items: start;
  
}

.flex-container-order-details-status-b1 {
  justify-content: start;
  align-items: start;
  padding: 0;
}
.flex-container-order-details-status-b2 {
  justify-content: start;
  align-items: start;
}

.flex-container-order-details-sizeQty {
  p,span {
    font-size: 0.8rem
  }
}

.flex-container-sizeQty {
  gap:5px;
  
}

.flex-container-order-details-products{

  h1{
    font-size: 2rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h2,h4,p{
    margin: 0
  }
  h2{
    font-size: 1rem;
    font-weight: 400;
  }
}

//================================================
//              IMAGE BIG SCALE
//================================================ 
.flex-container-image-big-scale {
  display: flex;
  flex-direction: column;
  gap:20px;
  }
  
  .flex-container-title-image-big-scale {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
  
    h1{
      font-weight: 300;
      margin: 0;
      font-size: 2rem;
    }
  }

  .flex-container-image-big-scale-info {
    flex-direction: column;
    
    h2 {
      margin: 0;
      padding-bottom: 0.2rem;
      font-size: 1rem;
    }
    
  }
  
  .box-container-img-big-scale {
    display: flex;
    justify-content: center;
  }
  
  .image-big-scale {
    width: 90%;
  }
  
  .img-close {
    width: 30px;
  }
  
  .img-big-scale-modal{
    
    z-index: 10000;
    width: 100%;
    top: 0%;
    left: 0%;
    bottom: 0;
  
    max-height: 100%;
    border: none;
    border-radius: 0;
    
  }


  //Footer

  .flex-continer-footer{
    flex-direction: column;
    align-items: start;
    gap: 35px;
  }


  //loading 

  .loading-capsules-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center both horizontally and vertically */
    background-color: $primary-color;
    min-height: 100vh;
    //padding-top: 2rem;
    gap: 20px;
  
    h1 {
      margin: 0;
      font-size: 2rem;
      color: white;
      font-weight: 300;
      text-align: center;
      width: 40%; /* Set the width to 75% of the viewport width */
      /* Adjust other styles as needed */
      // opacity: 0; /* Start with opacity 0 */
      // transform: translateY(20px); /* Start slightly below */
      // transition: opacity 0.5s, transform 0.5s; /* Transition opacity and transform */
    }
  }

  .keen {
    width: 25%;
  }

  //================================================
//                PRODUCT PAGE  
//================================================
.products-page-box {
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: 0.5fr 4fr 4fr 1fr 2fr;
  // gap: 10px;
  margin: 0.5rem 2rem;
  
}

.submenu-box {
  flex-direction: column;
  margin-bottom: 0 !important;

  h1 {
    align-self: start;
  }

  //margin: 0;
}

.capsules-box-container {
  margin-left: 0rem;
}

.capsules-box {
  display: flex;
  gap: 15px;
  //padding-left: 1rem;
  //margin-bottom: 1rem;
  align-items: center;
  justify-content: start;
  // overflow-x: auto; /* Enable horizontal scrolling */
  // white-space: nowrap; /* Prevent text wrapping */
 
  h2 {
    margin: 0;
    
    padding-bottom: 0.5rem;
    padding-top: 0rem;
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap; /* Prevent text wrapping */
    cursor: pointer;
  }

}


.products-box-flex-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 20px;
  justify-content:space-between;
  padding: 0;
}

.product-box-flex-container {
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  // margin-right: 20px;
  margin-bottom: 20px;
}

.product-box-flex-container:nth-child(3n) {
  margin-right: 0;
  clear: both;
}

.name-ref {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-page-img {
  
}

.srp {
  margin-top: 0.5rem !important;
  font-style: italic;
}

.prod-lenght {
  font-size: 1rem !important;
  margin-left: 3px;
  }

.info-product-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  //align-items: start;
  margin-top: 1rem;
  justify-content: space-between;

  h2 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: 400;
  }

  p {
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0; 
  }
  
}

.capsule-title-product-page {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 2rem;
  font-style: italic;
  margin: 0;
  margin-bottom: 1rem;
}

.name-price-box {


    h2 {
      font-size: 1.2rem !important;
    }
  
}

//change product capsule
.flex-container-change-capsule {
  flex-direction: column;
  gap: 20px;
}

//================================================
//                    ORDERS 
//================================================ 
.order-admin-container {
  p {
    margin: 0;
    font-size: 0.8rem;
  }
}



///client page////

.flex-container-client-status {
  
} 

.flex-container-client-page{
  flex-direction: column;
  justify-content: start;
  align-items: left;
}

.order-history-details {
  margin-top: 0.5rem;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start; /* Align items to the left */
 
  gap: 20px;
 
}

.ohd-pt1 {
  max-width: 100%;
}

.create-order-btn {
  margin: 0;
   width: 100%;
   padding: 0.8rem 4rem;
   font-weight: 300;
 }

 .shop-stock-btn {
  margin: 0;
   width: 100%;
   padding: 0.8rem 4rem;
   font-weight: 300;
 }


 //placeholders
 //placehokders
//$loader-gradient-color: linear-gradient(to right, #d6d6d6, #dedede);
.loading-placeholder-box {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  
  //height: 180px;
}

.loading-placeholder-b1 {
  //height: 130px;
}

.loading-placeholder-title {
  margin-top: 1rem;
  height: 30px;
  width: 100px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-1 {
  margin-top: 1rem;
  height: 20px;
  width: 200px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-2 {
  display: block;
  margin-top: 1rem;
  height: 20px;
  width: 300px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-btn {
  margin-top: 1rem;
  height: 40px;
  width: 250px;
  background: $loader-gradient-color;
  border-radius: 25px;
}

//admin
//placeholder admin
.loading-placeholder-box-admin {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  //height: 80px;
}

.loading-placeholder-b1-admin {
  //height: 100px;
}

.loading-placeholder-title-admin {
  margin-top: 1rem;
  height: 30px;
  width: 100px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-1-admin {
  margin-top: 1rem;
  height: 20px;
  width: 200px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-2-admin {
  
  margin-top: 1rem;
  height: 20px;
  width: 300px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-sentence-3-admin {
  
  margin-top: 1rem;
  height: 20px;
  width: 200px;
  background: $loader-gradient-color;
  border-radius: 10px;
}

.loading-placeholder-btn-admin {
  display: none;
  margin-top: 1rem;
  height: 40px;
  width: 250px;
  background: $loader-gradient-color;
  border-radius: 25px;
}

//================================================
//                TECHS
//================================================
.box-container-tech-page {
  margin: 0.5rem 2rem;
  margin-bottom: 4rem;
  min-height: 100vh;

  h1 {
    font-size: 4rem; 
    font-weight: 300;
    width: 66%;
    margin: 2rem 0;
  }
}

.flex-container-techs {
  padding: 0rem;

  h2{
    font-size: 2rem;
  }
  p {
    font-size: 1.2rem;
  }
}

.flex-container-individual-tech {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding:1rem 0;
  padding-right: 0rem;
  gap: 10px;

  h2 {
    text-align: left;
    margin: 0;
  }
  p{
    margin: 0;
  }
}

//new image big scale
.ibg-logo {
  margin: 1rem;
}

.ibg-box {
  margin: 1rem 2rem;
  margin-bottom: 4rem;
  }

.ibg-card-box {
flex-direction: column-reverse;
gap: 50px; 
margin-top: 1rem;
}

.info-block {
  width: 100%;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.2rem;
    //margin: 0;
  }
}

.image-block {
  width: 100%;
}

.ibg-italic {
  font-size: 1.5rem !important;
}

.ibg-title {
  align-items: center;
}

.ibg-name {
  font-size: 1.5rem !important;
}

.available-sizes {
  display: none;
}

.product-techs-box {
  margin-top: 4rem;
  padding-left: 0rem;
}

.product-techs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap:30px;
  margin-top: 2rem;
}

//================================================
//               ICONS - ORDER DETAILS
//================================================
.products-icons-order-details-setup-box {


  // span {
  //   font-size: 1.2rem !important; 

  // }
}

//================================================
//                PRODUCT ICON PAGE  
//================================================
.catalogue-box {
  display: flex;
  //flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.product-catalogue-img {
  max-width: 95px;
}


//================================================
//                ADMIN DASHBOARD PAGE  
//================================================
//////////////// TITLE//////
.grid-container-title-admin-dash {
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}

.title-admin-dash:nth-child(1) {
  grid-column: span 1; /* Second row, first column takes 2/3 of the width */
}


//////////////// STATS //////
.grid-container-stats-admin-dash {
  display: grid;
  //grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 10px; /* Adjust the gap as needed */
   
}

.c-stats-4-part-1 {
  flex-direction: row;
  border-bottom: 1px solid gray;
  height: 60px;
  align-items: center;

  h1 {
    font-size: 2rem;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.c-stats-4-part-1-category-info {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  
  h2 {
    font-size: 2rem;
   
  }
  h3{
    display: none;
    padding: 0;
 
  }
}

.c-stats-6 {
  display: none;
}

//////////// PRODUCTION
.production-admin-gender-box {
  display: flex;
  justify-content: space-between;
  background-color: $primary-color-body;
  
  position: sticky;
  top: 0;
  //opacity: 0.9;
  z-index: 5;

  h1 {
    margin: 0;
    
  }
}
/// 
th {
  display: none;
}



td {
display: block;
padding: 0.5rem 1rem;
width: 100% !important;
}

td:first-child {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

td:last-child {
  padding-bottom: 2rem;
}

td::before {
  content: attr(data-cell) ": ";
  font-weight: 500;
  font-size: 1rem;
}

td:not(first-child) {
  text-align: left;
  border: none;
}

.production-table-product-cell-box {
  margin-top: 0.75rem;
  
}

.production-table-product-name {
  display: none;
}

.btn-info-production-orders {
  width: auto;
  padding: 0.2rem 1rem;
  margin: 0;
}

//////////////////////////////////////////////////
/// CUSTOM DESIGN
///////////////////////////////////////////////////
.custom-design-container {
  padding-left: 1rem;
  h1 {
    color: white;
    font-size: 4.5rem;
    max-width: 200px;
  }

  h2 {
    color: white;
    font-weight: 300;
    font-size: 1.5rem;
  }
}

///// stock .btn-admin-dash.stock-available-box {
.stock-available-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3, span {
    font-size: 13px;
  }

  p {

    font-size: 13px;
  }
}

//============ lost items
.finder-box-order-page {
  display: flex;
  flex-direction: column;
  
  align-items: center;

  p {
    margin: 0;
    padding-top: 1rem;
  }
 
}

.client-header-info-box {
  h2 {
    font-size: 20px !important;
    font-weight: 400 !important;
  }
}

.container-point-system {

  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 25px;


}

.header-stock-available {
flex-direction: column;
align-items: normal;
  h2 {
    font-size: 1.4rem;
    font-weight: 300;
    margin: 0;
    padding-bottom: 0rem;
  }

  p {
  font-size: 1rem;
  }
}


.floating-btn {
  bottom: 5rem;
  width: 80%;
 
}

}





//MODAL
// @media (min-width: 768px) {
//   .modal {
//     left: calc(50% - 20rem);
//     width: 40rem;
//   }
// }

// @media (orientation: landscape) { 

//     //Log in

//   .input-log-in-username, .input-log-in-password {
//     width: 300px;
//   }

//   //Home page
//   .info {
//     max-width: 400px;
//     font-size: 3rem;
//     margin-top: 9rem;
//   }

// }



